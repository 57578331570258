<template>
  <div style="background-color: #f6f7fb">
    <Presentation />
  </div>
</template>

<script>
import Presentation from "../components/b2b2/Presentation";
export default {
  name: "B2b2",
  components: {
    Presentation,
  },
};
</script>