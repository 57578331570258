<template>
  <div class="card">
    <img class="ml-auto col-span-1" :src="icon" />
    <div class="col-span-2 mr-auto">
      <p class="title ml-3">{{ title }}</p>
      <p class="content">
        {{ content }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardB2b2",
  props: {
    icon: String,
    title: String,
    content: String,
  },
};
</script>

<style scoped>
.card {
  @apply grid grid-cols-3 grid-rows-1;
      display: flex;
          justify-content: center;
    align-items: flex-start;
}
.card {
  margin: 20px auto;
}
.title {
  width: 100%;
  height: auto;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
.content {
  width: 95%;
  height: auto;
  margin: 8px 0 0 16px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
</style>