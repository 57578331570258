<template>
  <div class="mt-5 flex items-center w-11/12 white-text">
    <div>
      <div
        class="mr-2 rounded-full h-5 w-5 flex items-center justify-center bg-tertiary white-text" 
      >
        <img
          class="color"
          src="../../assets/checkmarkwhite.svg"
          alt="exval checkmark"
        />
      </div>
    </div>
    <p class="text-primary ml-2 white-text" style="color:#fff;">
      {{ content }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SectionTarifPro",
  props: {
    courte: Boolean,
    icon: String,
    content: String,
  },
};
</script>

<style scoped>
.color {
  color: white;
}
</style>