<template>
  <div class="Testimonial">
    <div class="m-8">
      <div class="flex">
        <div class="star-rating">
          <label
            class="star-rating__star"
            v-for="(rating, index) in 5"
            :key="index"
            :class="
              card.star >= rating && card.star != null ? 'is-selected' : ''
            "
          >
            <input
              class="star-rating star-rating__checkbox"
              type="radio"
              :value="rating"
            />★</label
          >
        </div>
      </div>
      <p class="title">{{ card.title }}</p>
      <p class="content">
        {{ card.content }}
      </p>
      <div class="flex items-center">
        <img class="Ellipse" :src="card.icon" :alt="card.name" />
        <div class="ml-4">
          <p class="name">{{ card.name }}</p>
          <p class="lieu">{{ card.lieu }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardQualite",
  props: {
    card: Object,
  },
};
</script>

<style scoped>
.card {
  height: 19.875rem;
  @apply bg-white rounded-lg flex relative shadow-lg;
}
.star-rating__checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.star-rating__star {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.5em;
  color: #ababab;
  transition: color 0.2s ease-out;
}
.star-rating__star.is-selected {
  color: #ffd700;
}
.star-rating__star.is-disabled:hover {
  cursor: default;
}
.Testimonial {
  width: 392px;
  height: 383px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 40px 24px 13px 0;
  padding: NaNpx;
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}
.title {
  width: 225px;
  height: 49px;
  margin: 8px 103px 16px 0;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
.star {
  width: 97px;
  height: 32px;
  margin: 0 231px 8px 0;
  font-family: FontAwesome5Free-Solid;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1.6px;
  text-align: left;
  color: #f6c416;
}
.content {
  width: 328px;
  height: 121px;
  margin: 16px 0 33px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
.name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
.lieu {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4cbfde;
}
.Ellipse {
  width: 55px;
  height: 55px;
  margin: 5px 9px 0 0;
  object-fit: contain;
}

@media (max-width: 750px) {
  .Testimonial {
    margin: 40px auto 13px auto;
  }
  .content {
    width: 95%;
  }
}

@media (max-width: 425px) {
  .Testimonial {
    width: 95%;
  }
}
</style>