export const dataCard = [
  {
    icon: require("../../assets/b2b2/icon-tool-search.svg"),
    title: "Outil de recherche",
    content:
      "Une solution complète, pensée pour une utilisation quotidienne ou ponctuelle.",
  },
  {
    icon: require("../../assets/b2b2/analyse-detail.svg"),
    title: "Analyse détaillée",
    content:
      "Graphiques et statistiques, évolution du marché, données démographiques, ...",
  },
  {
    icon: require("../../assets/b2b2/rapport-perso.svg"),
    title: "Rapports personnalisés",
    content:
      "Fiches marchés et données graphiques disponibles sur l’ensemble du territoire.",
  },
  {
    icon: require("../../assets/b2b2/solution-pro.svg"),
    title: "Solution professionnelle",
    content:
      "Contenu et format adapté aux entreprises. Exportable pour vos clients.",
  },
  {
    icon: require("../../assets/b2b2/compare-trans.svg"),
    title: "Comparer les transactions",
    content:
      "10 ans d’historique sur l’ensemble du territoire français. Accès à des millions de données. ",
  }
];

export const dataCardObjectif = [
  {
    icon: require("../../assets/b2b2/discussion.svg"),
    content:
      "Une analyse sur mesure des données (Géoloc, filtres, données macro etc …)",
  },
  {
    icon: require("../../assets/b2b2/group-discu.svg"),
    content:
      "Des millions de transactions immobilières qualifiées, géolocalisées & filtrées",
  },
  {
    icon: require("../../assets/b2b2/grande-loupe.svg"),
    content: "Analyse de marché par code postal, exportable sous format PDF",
  },
  {
    icon: require("../../assets/b2b2/explication.svg"),
    content:
      "Exportation des données et des analyses des biens recherchés",
  },
];

export const dataSection = [
  {
    courte: true,
    icon: require("../../assets/checkmark.svg"),
    content:
      "Analyse sur mesure des données (Géoloc, filtres, données macro etc...)",
  },
  {
    courte: true,
    icon: require("../../assets/checkmark.svg"),
    content: "Observatoire & Analyse des offres immobilières en temps réel.",
  },
  {
    courte: true,
    icon: require("../../assets/checkmark.svg"),
    content: "Transactions immobilières qualifiées, géolocalisées & filtrées ",
  },
  {
    courte: false,
    icon: require("../../assets/close.svg"),
    content: "Exportation des données et des analyses (avec votre logo).",
  },
];
