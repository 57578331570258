<template>
  <div class="h-32 grid grid-cols-12 pt-8 container mr-auto ml-auto">
    <router-link class="col-span-2 flex justify-center items-center" to="/">
      <img class="ml-8" src="../../assets/logo-white.svg" alt="exval logo" />
    </router-link>
    <div
      class="col-start-3 col-end-9 flex items-center pl-6"
      style="position: relative"
    >
      <div
        class="hover:bg-gray lg:hidden col-span-1 col-start-4"
        @click="showMenu"
        style="margin-left: 20px; cursor: pointer"
      >
        <img src="../../assets/icons/menu.svg" />
      </div>
      <router-link
        class="col-span-1 Menu_text flex-initial text-primary hidden lg:block"
        to="/nos-outils"
      >
        Nos outils
      </router-link>
      <router-link
        class="col-span-1 Menu_text flex-initial text-primary hidden lg:block"
        to="/tarifs"
      >
        Tarifs
      </router-link>
      <router-link class="hidden" to="/aide"> Aide </router-link>
      <router-link
        class="col-span-1 Menu_text flex-initial text-primary hidden lg:block"
        to="/contact"
      >
        Contact
      </router-link>

      <div class="blk" v-if="responsive == true">
        <a
          class="col-span-1 Menu_text flex-initial text-primary blk lg:block"
          href="/nos-outils"
          v-if="responsive == true"
        >
          Nos outils
        </a>
        <a
          class="col-span-1 Menu_text flex-initial text-primary blk lg:block"
          href="/tarifs"
          v-if="responsive == true"
        >
          Tarifs
        </a>
        <a
          class="
            col-span-1
            Menu_text
            flex-initial
            text-primary
            blk
            lg:block
            hidden
          "
          href="/aide"
          v-if="responsive == false"
        >
          Aide
        </a>
        <a
          class="col-span-1 Menu_text flex-initial text-primary blk lg:block"
          href="/contact"
          v-if="responsive == true"
        >
          Contact
        </a>
      </div>
      <!--router-link class="Menu_text" to="/contact">Contact</router-link-->
    </div>
    <div class="col-start-10 col-span-3 grid pr-14 hidden-mobile">
      <div class="flex justify-center items-center">
        <div class="flex">
          <button class="CTA-3">
            <a
              target="_blank"
              href="https://exval.app/login"
              class="label m-auto"
              >Se connecter</a
            >
          </button>

          <button class="CTA-3">
            <a
              target="_blank"
              href="https://exval.app/register"
              class="label m-auto"
              >S'inscrire</a
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    test() {},
    showMenu() {
      this.responsive = !this.responsive;
    },
  },
  data() {
    return {
      responsive: false,
    };
  },
};
</script>


<style scoped>
.CTA-3 {
  width: 112px;
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 16px 0 0;
  padding: NaNpx;
  border-radius: 4px;
  background-color: #f7ac25;
}
.label {
  height: 20px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
.CTA-outline-1 {
  width: 87px;
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: NaNpx;
  border-radius: 4px;
  border: solid 1px #ffffff;
}
.label-white {
  height: 20px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.Menu_text {
  @apply text-sm mx-4 text-white;
}
.Nos-outils {
  height: 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.blk {
  position: absolute;
  top: 82%;
  z-index: 90;
  background: #fff;
  width: 100%;
  height: auto;
  border: 1px solid #ececec;
  left: 0;
}
.blk a {
  display: block !important;
  position: static;
  padding: 10px 20px;
  margin: 0;
  color: #363660;
}
/* Just mobile */
@media (max-width: 640px) {
  .hidden-mobile {
    display: none;
  }
}
</style>