<template>
  <div class="card">
    <div class="">
      <img class="m-auto" :src="icon" />
      <p class="content">
        {{ content }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardObjectif",
  props: {
    icon: String,
    content: String,
  },
};
</script>

<style scoped>
.card {
  width: 250px;
  height: 270px;
  margin: 0 82px 20px 20px;
  padding: 36px 24px 29px 25px;
  border-radius: 10px;
  box-shadow: 0 25px 100px 0 rgba(69, 88, 157, 0.08);
  background-color: #ffffff;
}
.content {
  width: 209.4px;
  height: 67.5px;
  margin: 8.8px 0 0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.5px;
  text-align: center;
  color: #363660;
}
@media(max-width: 750px) {
  .card {
    width: 250px;
    margin-right:auto;
    margin-left: auto;
  }
  .content {
        margin: 8px auto;
  }
}
</style>