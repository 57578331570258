<template>
  <div>
    <transition name="annonce" class="hide-annonce">
      <div v-show="showBarCovid" class="annonce bg-tertiary flex relative">
        <img
          class="mr-6"
          src="../../assets/images/marianne.svg"
          alt="marianne"
        />
        <p>
          Face au Covid-19, nous vous aidons à déployer la plateforme
          conversationnelle en 24h
        </p>
        <p class="ml-4 underline">En savoir +</p>
        <img
          class="absolute right-0 close"
          src="../../assets/close.svg"
          alt="close"
          @click="
            showBarCovid = false;
            showBar = true;
          "
        />
      </div>
    </transition>
    <transition name="annonce">
      <div v-show="showBar" class="annonce bg-secondary flex relative">
        <p>
          Vous découvrez la signature électronique ? Signez en illimité pour 10€
          /mois 😀
        </p>
        <p class="ml-4 underline">En savoir +</p>
        <img
          class="absolute right-0 close"
          src="../../assets/close.svg"
          alt="close"
          @click="showBar = false"
        />
      </div>
    </transition>
    <div class="bg">
      <Header />
      <div
        class="lg:flex justify-center item-center p-2 container ml-auto mr-auto"
      >
        <div class="w-6/5 pt-4">
          <p
            class="
              Rechercher-Comparer-et-Analyser-les-transactions
              text-quaternary
            "
          >
            Rechercher, Comparer et Analyser
          </p>
          <p
            class="Rechercher-Comparer-et-Analyser-les-transactions text-white"
          >
            les transactions immobilières en
          </p>
          <p
            class="Rechercher-Comparer-et-Analyser-les-transactions text-white"
          >
            quelques clics.
          </p>
          <div class="pt-8">
            <div class="w-6/6">
              <div class="grid gap-8 grid-cols-2">
                <a
                  class="CTA-Big-1 bg-secondary"
                  href="#solution"
                  v-smooth-scroll
                >
                  <label class="label text-white m-auto"
                    ><a>Découvrir notre solution</a>
                  </label>
                </a>
                <a
                  class="CTA-Big-1 bg-white"
                  href="#abonnement"
                  v-smooth-scroll
                >
                  <label class="label m-auto text-primary"
                    ><a>Abonnement & tarifs</a></label
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          flex-1 flex
          justify-center
          items-center
          container
          ml-auto
          mr-auto
        "
      >
        <img
          src="../../assets/presentation-app.png"
          alt="presenation-app"
          class="relative lg:top-20 presentation-app"
        />
      </div>
    </div>

    <div
      class="md:flex justify-center item-center container ml-auto mr-auto mt-5"
    >
      <div class="">
        <p class="title1" id="solution">LES FONCTIONNALITES</p>
        <p class="Smart-Jackpots-that-you-may-love-this-anytime-an">
          Rechercher, comparer et analyser rapidement
        </p>
        <div
          class="
            mt-20
            md:grid
            grid-flow-col grid-cols-3 grid-rows-2
            gap-10
            no-grid
            pl-5
            pr-5
          "
        >
          <CardB2b2
            v-for="(card, index) in dataCard"
            :key="index"
            :title="card.title"
            :icon="card.icon"
            :content="card.content"
          />
        </div>
      </div>
    </div>
    <div
      class="lg:flex justify-center item-center pt-20 container ml-auto mr-auto"
    >
      <div class="w-5/6" id="obj">
        <div class="md:grid grid-cols-2">
          <div class="md:grid grid-flow-col grid-cols-2 grid-rows-2 no-grid">
            <CardObjectif
              v-for="(card, index) in dataCardObjectif"
              :key="index"
              :icon="card.icon"
              :content="card.content"
            />
          </div>
          <div class="m-auto">
            <div class="m-20">
              <p class="title-objectif">
                Tout ce dont vous rêviez nous l’avons fait pour vous
              </p>
              <p class="text-primary">
                Les solutions ExVal sont à la fois à la destination des experts
                mais également des notaires, banquiers, promoteurs, agents
                immobiliers…
              </p>
              <div class="mt-10">
                <div class="flex items-center">
                  <div>
                    <div
                      class="
                        mr-2
                        rounded-full
                        h-5
                        w-5
                        flex
                        items-center
                        justify-center
                        bg-tertiary
                      "
                    >
                      <img
                        src="../../assets/checkmarkwhite.svg"
                        alt="exval checkmark"
                      />
                    </div>
                  </div>
                  <p class="text-primary text-lg">Big data</p>
                </div>
                <div class="flex mt-4 items-center">
                  <div>
                    <div
                      class="
                        mr-2
                        rounded-full
                        h-5
                        w-5
                        flex
                        items-center
                        justify-center
                        bg-tertiary
                      "
                    >
                      <img
                        src="../../assets/checkmarkwhite.svg"
                        alt="exval checkmark"
                      />
                    </div>
                  </div>
                  <p class="text-primary text-lg">Smart data</p>
                </div>
                <div class="flex mt-4 items-center">
                  <div>
                    <div
                      class="
                        mr-2
                        rounded-full
                        h-5
                        w-5
                        flex
                        items-center
                        justify-center
                        bg-tertiary
                      "
                    >
                      <img
                        src="../../assets/checkmarkwhite.svg"
                        alt="exval checkmark"
                      />
                    </div>
                  </div>
                  <p class="text-primary text-lg">Intelligent data (Exval)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-auto pt-20 w-1/2 container ml-auto mr-auto">
      <img src="../../assets/b2b2/image-deco.png" alt="image-deco" />
    </div>
    <div
      class="pt-20 lg:flex justify-center item-center container ml-auto mr-auto"
    >
      <div class="w-6/6" style="padding: 0 20px">
        <p class="title1">APPROUVÉE PAR DES MILLIERS</p>
        <p class="Smart-Jackpots-that-you-may-love-this-anytime-an">
          Notre solution en a séduit plus d'un
        </p>
        <div
          class="
            pt-14
            md:grid
            grid-flow-col grid-cols-3 grid-rows-1
            gap-10
            no-grid
          "
        >
          <CardQualite
            v-for="(card, index) in dataQualite"
            :key="index"
            :card="card"
          />
        </div>
      </div>
    </div>
    <div
      class="pt-20 lg:flex justify-center item-center container ml-auto mr-auto"
    >
      <div class="">
        <p class="title1" id="abonnement">UNE SOLUTION SUR MESURE</p>
        <p class="Smart-Jackpots-that-you-may-love-this-anytime-an">
          Nos abonnements
        </p>
        <div
          class="pt-14 md:grid grid-cols-2 grid-rows-1 flex items-stretch"
          id="aize"
        >
          <div
            class="rounded-lg bg-white self-end tarif-pro blue-bg"
            v-for="p in plans"
            :key="p.id"
          >
            <div class="flex justify-between">
              <button class="btn-pro">
                <label class="Suggested white-text">{{ p.name }}</label>
              </button>
              <div class="flex">
                <span class="abo-mensual-annuel white-text"
                  >Abo {{ p.type }}</span
                >
              </div>
            </div>
            <div class="mt-8">
              <div
                v-if="p.type == 'Annuel'"
                class="flex justify-between text-primary mr-8"
              >
                <div>
                  <p class="font-bold white-text" style="font-size: 22px">
                    Annuel
                  </p>
                  <p class="text-primary white-text">Abonnement annuel</p>
                </div>
                <div class="relative w-1/2">
                  <p class="flex justify-end text-primary" style="color: #fff">
                    Prix HT par mois
                  </p>
                  <p
                    class="text-tertiary flex justify-end font-bold"
                    style="font-size: 26px"
                  >
                    {{ p.prices.annual.amount }} € / mois 
                  </p>
                  <p
                    class="text-tertiary font-bold absolute lg:right-0"
                    style="font-size: 13px"
                  >
                  </p>
                </div>
              </div>

              <div
                v-if="p.type == 'Mensuel'"
                class="flex justify-between text-primary mr-8"
              >
                <div>
                  <p class="font-bold white-text" style="font-size: 22px">
                    Mensuel
                  </p>
                  <p class="text-primary white-text">Abonnement mensuel</p>
                </div>
                <div class="relative w-1/2">
                  <p
                    class="flex justify-end text-primary white-text"
                    style="color: #fff"
                  >
                    Prix HT par mois
                  </p>
                  <p
                    class="text-tertiary flex justify-end font-bold"
                    style="font-size: 26px"
                  >
                    {{ p.prices.month.amount }} € / mois 
                  </p>
                </div>
              </div>

              <div class="mt-10" style="min-height: 255px">
                <SectionTarifPro
                  v-for="(card, index) in p.fonctionnalities"
                  :key="index"
                  :icon="require('../../assets/checkmark.svg')"
                  :content="card"
                  :courte="true"
                />
              </div>
              <a
                target="_blank"
                href="https://exval.app/register"
                v-if="p.type == 'Annuel'"
              >
                <span class="CTA-Big-primary m-auto border-white">
                  <label class="label text-white m-auto">Je m'abonne</label>
                </span>
              </a>

              <a
                target="_blank"
                href="https://exval.app/register"
                v-if="p.type == 'Mensuel'"
              >
                <span class="CTA-Big-primary m-auto border-white">
                  <label class="label text-white m-auto">Je m'abonne</label>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="py-20 lg:flex justify-center item-center container ml-auto mr-auto"
  >
    <div class="w-4/6 grid gap-4 pl-5 pr-5 hidden" id="quest">
      <!-- <Question :questions="questions" /> -->
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Header from "./Header";
import CardB2b2 from "./Card";
import CardObjectif from "./CardObjectif";
import CardQualite from "./CardQualite";
//import SectionTarif from "./SectionTarif";
import SectionTarifPro from "./SectionTarifPro";
// import Question from "./Question";
// import Swipper from "./Swipper";
import { dataCard, dataCardObjectif, dataSection } from "./data";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "Presentation",
  components: {
    Header,
    CardB2b2,
    CardObjectif,
    CardQualite,
    // SectionTarif,
    SectionTarifPro,
    // Question,
    // Swipper,
  },
  mounted() {
    this.fetchQualite();
    this.fetchPlan();
  },
  data() {
    return {
      questions: [],
      plans: [],
      dataQualite: [],
    };
  },
  setup() {
    let swipper = ref(true);
    const onSwippe = () => {
      swipper.value = !swipper.value;
    };
    let showBar = ref(false);
    let showBarCovid = ref(true);
    return {
      dataCard,
      dataCardObjectif,
      dataSection,
      swipper,
      onSwippe,
      showBar,
      showBarCovid,
    };
  },
  methods: {
    swippe(id) {
      const p = this.plans.map(function (item) {
        if (item.id == id) {
          item.swipper = !item.swipper;
        }
        return item;
      });
      this.plans = [...p];
    },
    fetchQualite() {
      const self = this;
      axios({
        method: "GET",
        url: "https://api.exval.fr/api/v1/static-page/testimony",
      })
        .then(function (response) {
          if (response.data.code == 200) {
            const datas = response.data.datas;
            const qualite = datas.map(function (item) {
              return {
                star: item.star,
                title: item.title,
                content: item.testimony,
                lieu: item.city,
                icon: item.img,
                name: item.name,
              };
            });
            self.dataQualite = qualite;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    fetchFaq() {
      const self = this;
      axios({
        method: "GET",
        url: "https://api.exval.fr/api/v1/static-page/faq",
      })
        .then(function (response) {
          if (response.data.code == 200) {
            const datas = response.data.datas;
            self.questions = datas;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    fetchPlan() {
      const self = this;
      axios({
        method: "GET",
        url: "https://api.exval.fr/api/v1/static-page/plans",
      })
        .then(function (response) {
          if (response.data.code == 200) {
            const datas = response.data.datas;
            self.plans = datas.map(function (item) {
              let i = item;
              i["swipper"] = false;
              return i;
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.presentation-app {
  max-width: 1040px;
  width: 100%;
  height: 530.8px;
  object-fit: contain;
}
.input-search {
  @apply border-2 border-gray-300 rounded p-2 pl-12;
}
.arrow-left {
  @apply w-5 h-5 mt-3;
}
.search {
  @apply arrow-left ml-5 absolute;
}
.fonction {
  @apply grid grid-cols-3 grid-rows-1;
}
.CTA-Big-1 {
  min-height: 36px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: NaNpx;
  border-radius: 4px;
  min-width: 160px;
}
.label {
  height: 20px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}
.Rechercher-Comparer-et-Analyser-les-transactions {
  font-family: Poppins;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -1px;
  text-align: center;
}
.Rechercher-Comparer-et-Analyser-les-transactions .text-style-1 {
  color: #f7ac25;
}
.illustration {
  width: 1040px;
  height: 530.8px;
  margin: 475px 198px 55.2px 202px;
  padding: 100.1px 24.1px 213.6px 265.4px;
  object-fit: contain;
}
.bg {
  height: 798px;
  margin: 0px 0 263px;
  background-color: #363660;
  background-image: url("../../assets/b2b2/bg-transparent.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  @apply bg-primary relative;
}
.Core-features {
  width: 205px;
  height: 40px;
  margin: 0 117px 0 151px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: 3px;
  text-align: center;
  color: #e94a73;
}
.Smart-Jackpots-that-you-may-love-this-anytime-an {
  height: auto;
  font-family: Poppins;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -1px;
  text-align: center;
  color: #363660;
}
.annonce {
  min-height: 50px;
  padding: 13px 24px 13px 10px;
  justify-content: center;
}
.Vous-dcouvrez-la-signature-lectronique-Signez {
  height: 20px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #363660;
}
.En-savoir- {
  height: 20px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #363660;
}
.close {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  object-fit: contain;
  cursor: pointer;
}
.CTA-big-outline-2 {
  width: 128px;
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 116px 25px 0 121px;
  padding: NaNpx;
  border-radius: 4px;
  border: solid 1px #ffffff;
}
.CTA-Big-primary {
  width: 129.8px;
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 116px;
  padding: NaNpx;
  border-radius: 4px;
  background-color: #363660;
}
.tarif-court {
  width: 460px;
  height: 600px;
  padding: 55px 40px 33px;
  background-color: #363660;
}
.tarif-pro {
  width: 480px;
  height: 680px;
  padding: 25px 12.7px 24px 40px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 0 10px;
}
.btn-pro {
  height: auto;
  padding: 2.1px 10.2px 1.1px 7.1px;
  border-radius: 3px;
  background-color: #e94a73;
}
.Suggested {
  width: 97.4px;
  height: 29.8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.14px;
  text-align: left;
  color: #ffffff;
}
.abo-mensual-annuel {
  height: 20px;
  margin: 5px 10px 5px 0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #363660;
}
.title1 {
  height: 40px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: 3px;
  text-align: center;
  color: #e94a73;
}
.title-objectif {
  max-width: 463px;
  width: auto;
  height: auto;
  margin: 0 0 73px 1px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
}
.annonce-enter-from,
.annonce-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
}

.annonce-enter-active,
.annonce-leave-active {
  transition: all 0.3s;
}
.opacity {
  opacity: 0.6;
  z-index: 2;
}
.blue-bg {
  background-color: #363660;
}
.white-text {
  color: #fff !important;
}
.border-white {
  border: 1px solid #fff;
}
/* temporarily apparently */
.hide-annonce {
  display: none;
}
</style>
